import { IconButton, Stack, Tooltip } from '@mui/material';
import { ICON_CELL_SIZE } from 'constants/appConstants';
import { CommonGridProvider } from 'context/common/commonGrid.context';
import { SidebarContext } from 'context/sidebar.context';
import useCustomFetch from 'hooks/common/CommonGrid/fetch.hooks';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
    ExtraQueryFiltersProps,
    ModalActionProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { DeleteHook } from 'types/common/general.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from './CommonGrid';
import CanDelete from './Extra/CanDelete';
import CanDisable from './Extra/CanDisable';
import CustomTableAction from './Extra/CustomTableAction';
import CustomIconSelector from '../CustomIconSelector';
import { copyToClipboard } from 'utils/helpers/commonHelper';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { resourceAbilities } from 'constants/abilities.constants';

const PreCommonGridContainer = (props: Props) => {
    const {
        columns,
        resource,
        canDisable = false,
        canSearch = false,
        extraFilters,
        extraActionsInHeader,
        defaultSortFieldId,
        defaultSortAsc = false,
        useGetData,
        customActionColumns,
        clientSidePagination,
        adjustHeight,
        deleteHook = null,
        rowHeight = 30,
        conditionalRowStyles,
        refreshTable,
        defaultSortDirection,
        defaultFilters,
        backTableId = undefined,
        fake = false,
        canExport = false,
        ghostColumns,
    } = props;

    const {
        data,
        loading,
        totalRows,
        pageSize,
        handlePageChange,
        handleSort,
        setSearchText,
        refetch,
        filterHeaderString,
        setFilterHeaderString,
        showHeaderFilters,
        setShowHeaderFilters,
    } = useCustomFetch(
        useGetData,
        canDisable ?? true,
        clientSidePagination ?? false,
        adjustHeight ?? 0,
        canSearch ?? true,
        rowHeight ?? 30, // 30 es el valor por defecto del height de las filas.
        extraFilters,
        defaultSortFieldId,
        defaultFilters,
        backTableId,
        defaultSortDirection,
    );

    const refetchTable = () => refetch();

    const { t } = useTranslation('commonGrid');
    const { SnackBar } = useSnackBar();
    const { drawerWidth } = useContext(SidebarContext);
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const getAbilityByResource = (resource: string) => {
        return resourceAbilities[resource.replace('-', '_').toUpperCase()] ?? '';
    };

    const extraColumnsActions = React.useCallback(
        (row: any) => (
            <Stack direction="row">
                <Tooltip title={t('copy_id')}>
                    <IconButton onClick={() => copyToClipboard(row.id)}>
                        <CustomIconSelector type="copy" sx={ICON_CELL_SIZE} key={row.id} />
                    </IconButton>
                </Tooltip>
                {customActionColumns?.map((customActionColumn: ActionColumn) => {
                    if (
                        (customActionColumn.isHidden && customActionColumn.isHidden(row)) ||
                        row.disableRender
                    )
                        return <></>;
                    if (customActionColumn.onIconClick) {
                        return (
                            <Tooltip
                                key={customActionColumn.id}
                                title={t(customActionColumn.id.replaceAll('-', '_'), {
                                    ns: 'cdtModal',
                                })}>
                                <IconButton onClick={() => customActionColumn.onIconClick(row)}>
                                    {customActionColumn.icon({
                                        sx: ICON_CELL_SIZE,
                                        row,
                                    })}
                                </IconButton>
                            </Tooltip>
                        );
                    }
                    return (
                        <CustomTableAction
                            key={customActionColumn.id}
                            row={row}
                            component={
                                customActionColumn.component as (
                                    props: ModalActionProps,
                                ) => JSX.Element
                            }
                            Icon={customActionColumn.icon}
                            width={customActionColumn.width}
                            modalTitle={resource}
                            type={`${customActionColumn.id}`}
                            customizedTitle={customActionColumn.customizedTitle}
                        />
                    );
                })}
                {canDisable && (
                    <CanDisable id={row.id} resource={resource} isDisabled={row.is_disabled} />
                )}
                {deleteHook && !isHiddenByAbility(getAbilityByResource(resource)) && (
                    <CanDelete id={row.id} resource={resource} deleteHook={deleteHook} />
                )}
            </Stack>
        ),
        [],
    );

    let finalColumns = columns;
    let finalWithGhostColumns: any[] = [];

    if (ghostColumns && ghostColumns.length > 0)
        finalWithGhostColumns = [...columns, ...ghostColumns];

    if (canDisable || !!deleteHook || (customActionColumns && customActionColumns?.length > 0))
        finalColumns = columns.concat([
            {
                cell: extraColumnsActions,
                name: t('actions'),
                center: true,
                maxWidth: '200px',
                omitExport: true,
            },
        ]);

    const childProps = {
        ...props,
        clientSidePagination: clientSidePagination ?? false,
        defaultSortAsc: defaultSortAsc ?? false,
        data,
        finalColumns,
        loading,
        pageSize,
        totalRows,
        handlePageChange,
        handleSort,
        t,
        SnackBar,
        drawerWidth,
        showHeaderFilters,
        filterHeaderString,
        setFilterHeaderString,
        refetch,
        setShowHeaderFilters,
        extraActionsInHeader,
        setSearchText,
        useGetData,
        conditionalRowStyles,
        fake,
        canExport,
        finalColumnsForExport:
            finalWithGhostColumns.length > 0 ? finalWithGhostColumns : finalColumns,
    };

    React.useEffect(() => {
        refetchTable();
    }, [refreshTable]);

    return <CommonGrid {...childProps} />;
};
const CommonGridContainer = ({ ...props }: Props) => (
    <CommonGridProvider>
        <PreCommonGridContainer {...props} />
    </CommonGridProvider>
);

const propTypes = {
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    ghostColumns: PropTypes.arrayOf(PropTypes.any),
    title: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    canDisable: PropTypes.bool,
    canSearch: PropTypes.bool,
    fake: PropTypes.bool,
    defaultSortFieldId: PropTypes.string.isRequired,
    defaultSortAsc: PropTypes.bool,
    useGetData: PropTypes.any.isRequired,
    exportName: PropTypes.string,
    clientSidePagination: PropTypes.bool,
    adjustHeight: PropTypes.number,
    rowHeight: PropTypes.number,
};

interface extraProps {
    extraFilters?: Array<ExtraQueryFiltersProps>;
    extraActionsInHeader?: ActionHeader[];
    customActionColumns?: ActionColumn[];
    headerFilters?: CommonFilter[];
    deleteHook?: () => DeleteHook;
    defaultSortDirection?: 'asc' | 'desc';
    conditionalRowStyles?: { when: Function; style: Object }[];
    refreshTable?: boolean;
    defaultFilters?: Array<ExtraQueryFiltersProps>;
    backTableId?: number;
    canExport?: boolean;
}

type Props = InferPropsExtended<typeof propTypes, extraProps>;
CommonGridContainer.propTypes = propTypes;

export default CommonGridContainer;
