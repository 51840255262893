import * as XLSX from 'xlsx';
import moment from 'moment';
import {
    ACCEPTED,
    ACTIVE,
    APPROVED_API,
    APPROVED_MANUALLY,
    ARS,
    ARS_LOCALE,
    ASSIGNED,
    BROKER,
    CANCELLED,
    CANCELLED_PARTIALLY,
    COMPLETED,
    CONFIRMED,
    DELETED,
    DESTINATION,
    ELIMINATED,
    ERROR,
    EXPIRED,
    EXPIRED_BECAUSE_DDJJ,
    EXPIRED_PARTIALLY,
    INTERMEDIARY,
    PARTIALLY_FILLED,
    PENDING,
    PENDING_ACCEPT,
    PENDING_CANCELLATION,
    PENDING_DELETE,
    PENDING_VALIDATION,
    PENDING_WITHDRAWAL,
    REJECTED,
    SCHEDULED,
    SENT,
    tClient,
    tCommonGrid,
    tErrorsContext,
    USD,
    VALIDATED,
    VALIDATION_FAILED,
    VALIDATION_RETRY,
} from 'constants/appConstants';
import { getExpirationToken } from 'storage/auth.storage';
import i18n from 'utils/i18n';
import { UserAccount } from 'types/users/users.api.types';
import { REGEX_0_TO_9 } from './constants';
import {
    BankAccountExtraData,
    SortedBankAccount,
} from 'types/clientExtractions/clientExtractions.types';
import { ProspectStateInfo } from 'types/clients/clients.types';
import { t } from 'i18next';

export const later = (delay: number, value?: any) =>
    new Promise(resolve => setTimeout(resolve, delay, value));

export const laterCancellable = (delay: number, value?: any) => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    let reject: ((reason?: any) => void) | null = null;
    const promise = new Promise((resolve, _reject) => {
        reject = _reject;
        timer = setTimeout(resolve, delay, value);
    });
    return {
        get promise() {
            return promise;
        },
        cancel() {
            if (timer) {
                clearTimeout(timer);
                timer = null;
                if (reject) reject();
                reject = null;
            }
        },
    };
};

export const getAmericanStringNumber = (stringNumber: string | null) => {
    return stringNumber
        ? stringNumber.replace(/,/g, 'temp').replace(/\./g, ',').replace(/temp/g, '.')
        : stringNumber;
};

export const formatDateToAMPM = (date: Date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes().toString();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12;
    minutes = Number(minutes) < 10 ? '0' + minutes : minutes;
    const strTime = (Number(hours) < 10 ? '0' + hours : hours) + ':' + minutes + ' ' + ampm;
    return strTime;
};

export const toCurrencyString = (
    num: number,
    decimalPositions = 2,
    currency: null | string = null,
    spacedCurrency = true,
) => {
    if (num == null || isNaN(num))
        return `${currency ?? ''}${currency != null && spacedCurrency ? ' ' : ''}${(0)
            .toFixed(decimalPositions)
            .replace('.', ',')}`;
    return `${currency ?? ''}${currency != null && spacedCurrency ? ' ' : ''}${num
        .toFixed(decimalPositions)
        .replace('.', ',')
        .replace(
            decimalPositions == 0 ? /(\d)(?=(\d{3})+(?!\d))/g : /(\d)(?=(\d{3})+(?!\d)?,)/g,
            '$1.',
        )}`;
};
export const lowerFirstLetter = (string: string) =>
    `${string.charAt(0).toLowerCase()}${string.slice(1)}`;

export const formatPhoneNumber = (phoneNumber: string) => {
    const phoneNumberSplitted = phoneNumber.split(' ');
    const country = phoneNumberSplitted[0];
    const area = phoneNumberSplitted[1];
    const number = phoneNumberSplitted[2];
    switch (country) {
        case '+1':
            return `${country} (${area}) ${number.slice(0, 4)}-${number.slice(4)}`;
        case '+549':
            return `${country.slice(0, 3)} ${country.charAt(3)} ${area} ${number.slice(
                0,
                4,
            )} ${number.slice(4)}`;
        default:
            return phoneNumber;
    }
};
const fitToColumn = (data: Array<any>) => {
    const columnWidths = [];
    for (const property in data[0]) {
        if (property) {
            columnWidths.push({
                wch: Math.max(
                    property ? property.toString().length : 0,
                    ...data.map(obj => (obj[property] ? obj[property].toString().length : 0)),
                ),
            });
        }
    }

    return columnWidths;
};

const formatExportResourceName = (resource: string) => {
    const resourceFormated = lowerFirstLetter(resource.replaceAll('-', '_'));

    const parts = resourceFormated.split('_');
    const filteredParts = parts.filter(part => !['market', 'client', 'channel'].includes(part));
    const date = moment().format('-YYYY-MM-DD');

    const fileNameExportFormatted = filteredParts.join('_') + date;

    if (fileNameExportFormatted.length > 31) {
        const shortenedParts = filteredParts.map(part => part.slice(0, 3));
        return shortenedParts.join('_') + date;
    }

    return fileNameExportFormatted;
};

export const exportFromJson = (data: Array<any>, columns: Array<string>, name: string) => {
    const ws = XLSX.utils.json_to_sheet(data, {
        header: columns,
    });

    const nameFormatted = formatExportResourceName(name);

    const wscols = fitToColumn(data);
    ws['!cols'] = wscols;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, nameFormatted);
    XLSX.writeFile(wb, `${nameFormatted}.xlsx`);
};

export const checkLoginExpired = (date?: string) => {
    let expirationDate;
    if (date && date != '') expirationDate = date;
    else expirationDate = getExpirationToken();
    try {
        if (expirationDate && expirationDate != '') {
            const now = moment();
            const expiration = moment(expirationDate);
            const isExpired = !now.isBefore(expiration);
            return isExpired;
        }
        return false;
    } catch (Ex) {
        throw new Error('Usuario deslogueado');
    }
};

export const messagesToDeleteRegistration = (props: { resource: string; error: any }) => {
    const { resource, error } = props;
    return {
        errorMessage: i18n.t(
            error?.response?.data?.error_messages?.[0].code ?? 'error_deletion_snackbar_text',
            { ...tErrorsContext, complement: i18n.t('use_delete_error_plugin', { ns: resource }) },
        ),
        successfulMessage: i18n.t('successful_deletion_snackbar_text', {
            ...tCommonGrid,
            complement: i18n.t('use_delete_success_plugin', { ns: resource }),
        }),
    };
};

export const download = (link: string) => {
    const element = document.createElement('a');
    element.href = link;
    element.download = link.split('/').reverse()[0];
    element.target = '_blank';
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const convertCurrencyFormat = (price: number, format = ARS) =>
    new Intl.NumberFormat(ARS_LOCALE, {
        style: 'currency',
        currency: format.includes('USD') ? USD : ARS,
    }).format(price);

export const getFullName = (user?: { name: string; lastName: string } | null) =>
    user ? `${user?.name} ${user?.lastName}` : '-';

export const getMarketAccounts = (marketAccounts: UserAccount[]) =>
    marketAccounts.map(({ customerCode }) => customerCode).join(', ');

export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export const getActionsByStatus = (status: string) => {
    switch (status) {
        case COMPLETED:
            return ['edit'];
        case ASSIGNED:
        case PENDING:
            return ['approve', 'reject'];
        case PENDING_DELETE:
            return ['delete'];
        case PENDING_ACCEPT:
            return ['approve', 'edit', 'reject'];
        case PENDING_WITHDRAWAL:
            return ['approve_api', 'approve_manually', 'reject'];
        default:
            return [];
    }
};

export const getColorByStatus = (status: string) => {
    switch (status) {
        case ERROR:
        case EXPIRED:
        case EXPIRED_BECAUSE_DDJJ:
        case REJECTED:
        case CANCELLED:
        case CANCELLED_PARTIALLY:
        case EXPIRED_PARTIALLY:
        case ELIMINATED:
        case DELETED:
        case VALIDATION_FAILED:
            return {
                backgroundColor: '#ffc09f',
            };
        case PENDING:
        case ASSIGNED:
        case SCHEDULED:
        case PENDING_ACCEPT:
        case PENDING_DELETE:
        case PENDING_VALIDATION:
        case VALIDATION_RETRY:
        case PARTIALLY_FILLED:
        case SENT:
        case PENDING_CANCELLATION:
            return {
                backgroundColor: '#ffee93',
            };
        case ACCEPTED:
        case COMPLETED:
        case ACTIVE:
        case APPROVED_API:
        case APPROVED_MANUALLY:
        case CONFIRMED:
        case VALIDATED:
            return {
                backgroundColor: '#adf7b6',
            };
        default:
            return {};
    }
};

export const handleChangeNumber = (
    key: string,
    value: string,
    setFieldValue: (field: string, value: any) => void,
    fn1?: () => void,
) => {
    if (REGEX_0_TO_9.test(value)) {
        fn1 && fn1();
        return setFieldValue(key, value);
    }
    if (value === '') {
        fn1 && fn1();
        setFieldValue(key, '');
    }
};
export const downloadFromURL = (url: string) => window.open(url, '_blank');

export const sortBankAccounts = (bankAccountExtraData?: BankAccountExtraData[]) =>
    bankAccountExtraData?.reduce((accum: SortedBankAccount, account) => {
        switch (account.extraType) {
            case DESTINATION:
            case BROKER:
                return (accum = { ...accum, [account.extraType]: account });
            case INTERMEDIARY:
                return (accum = {
                    ...accum,
                    INTERMEDIARY: accum?.INTERMEDIARY?.length
                        ? accum.INTERMEDIARY.concat(account)
                        : [account],
                });
            default:
                return accum;
        }
    }, {} as SortedBankAccount);

export const hasStepCompleted = (steps: ProspectStateInfo[], step: string) => {
    const filterStep = steps.filter(s => s.name === t(step, tClient))[0];
    return filterStep && filterStep?.status === 'COMPLETED';
};
