import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikProvider } from 'formik';
import { Autocomplete, Box, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { tCommon } from 'constants/appConstants';
import CustomCheckbox from 'components/common/CustomCheckbox';
import CustomInput from 'components/common/CustomInput';
import ActionButton from 'components/common/ActionButton';
import UserExceptionsTable from 'components/common/UserExceptionsTable';
import SettingsAlertsFormMessageEditor from './SettingsAlertsFormMessageEditor';
import { pxToRem } from 'utils/helpers/stylesHelper';

const platformOptions = ['web', 'mobile'];
const recipientsOptions = ['channelUsers', 'finalUsers', 'backofficeUsers'];

const SettingsAlertsForm = ({
    formik,
    isLoading,
    isLoadingEdit,
    t,
    handleChangePlatforms,
    close,
}: Props) => {
    const { errors, values, handleChange } = formik;

    return (
        <FormikProvider value={formik} data-testid="SettingsAlertsForm-form">
            <Form>
                <Grid container rowSpacing={2} px={3} py={2}>
                    <Grid item xs={12} md={6}>
                        <CustomCheckbox
                            name="active"
                            label={t('alerts_active')}
                            checked={values?.active}
                            handleChange={({ target: { name, checked } }) =>
                                handleChange({ target: { name, value: checked } })
                            }
                        />
                    </Grid>
                    {!values.recipients.includes('backofficeUsers') && (
                        <Grid item xs={12} md={6}>
                            <CustomCheckbox
                                name="blocking"
                                label={t('alerts_blocker')}
                                checked={values?.blocking}
                                handleChange={({ target: { name, checked } }) =>
                                    handleChange({ target: { name, value: checked } })
                                }
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={10}>
                        <Typography mb={0.625}>{t('alerts_name')}</Typography>
                        <CustomInput
                            required
                            field="name"
                            label={t('alerts_name')}
                            placeholder={t('alerts_name_placeholder') as string}
                            values={values}
                            errors={errors}
                            handleChange={(value: string) =>
                                handleChange({ target: { name: 'name', value } })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Typography mb={0.625}>{t('alerts_recipients')}</Typography>
                        <Autocomplete
                            multiple
                            id="recipients"
                            size="small"
                            options={recipientsOptions}
                            getOptionLabel={option => t(`alerts_${option}`) as string}
                            value={values?.recipients}
                            onChange={(_, value) => {
                                if (value.includes('backofficeUsers')) {
                                    handleChange({ target: { name: 'blocking', value: false } });
                                }
                                handleChange({ target: { name: 'recipients', value } });
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size="small"
                                    placeholder={t('alerts_recipients')}
                                    error={!!errors?.recipients}
                                    helperText={errors?.recipients?.toString()}
                                />
                            )}
                        />
                    </Grid>
                    {values?.recipients?.includes('finalUsers') && (
                        <Grid item xs={12} md={6}>
                            <Typography mb={0.625}>{t('alerts_platform')}</Typography>
                            <Box display="flex" ml={0.5}>
                                {platformOptions.map((option: string) => (
                                    <CustomCheckbox
                                        key={option}
                                        name="platforms"
                                        label={t(`alerts_${option}`)}
                                        checked={values?.platforms.includes(option)}
                                        handleChange={({ target: { name, checked } }) =>
                                            handleChangePlatforms(name, option, checked)
                                        }
                                    />
                                ))}
                            </Box>
                        </Grid>
                    )}
                    <Divider sx={{ width: '100%', mt: 1.25 }} />
                    <UserExceptionsTable
                        exceptions={values?.exceptions}
                        handleChange={handleChange}
                    />

                    <Divider sx={{ width: '100%', mt: 2 }} />
                    <Box width={{ lg: pxToRem(800), md: '80vw', xs: '90vw' }}>
                        <SettingsAlertsFormMessageEditor
                            message={values?.message}
                            messageError={errors?.message?.toString()}
                            handleChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Stack direction="row" display="flex" spacing={1} justifyContent="flex-end">
                    <ActionButton onClick={close} variant="outlined">
                        {t('cancel', tCommon)}
                    </ActionButton>
                    <ActionButton
                        type="submit"
                        disabled={isLoading || isLoadingEdit}
                        loading={isLoading || isLoadingEdit}>
                        {t('save', tCommon)}
                    </ActionButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    formik: any;
    isLoading: boolean;
    isLoadingEdit: boolean;
    t(text: string, ns?: { ns: string }): string | undefined;
    handleChangePlatforms(name: string, option: string, checked: boolean): void;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsAlertsForm.propTypes = propTypes;
SettingsAlertsForm.defaultProps = defaultProps;

export default SettingsAlertsForm;
