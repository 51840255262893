import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ChannnelsAffidavit } from 'types/channelsAffidavit/channelsAffidavit.types';
import { useChannnelsAffidavit } from 'hooks/api/channelsAffidavit.hooks';
import CommonGrid from 'components/common/CommonGrid';

const ChannelsAffidavitPage = (props: Props) => {
    const { t, columns, actionColumns, headerFilters, headerActions, SnackBar } = props;
    return (
        <>
            <CommonGrid
                title={t('channels_affidavit_list_title')}
                columns={columns}
                resource="channels-affidavit"
                useGetData={useChannnelsAffidavit}
                defaultSortFieldId="createdAt"
                canExport
                customActionColumns={actionColumns}
                headerFilters={headerFilters}
                extraActionsInHeader={headerActions}
                extraFilters={[{ query: 'hasDeclaration', queryValue: true }]}
            />
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<ChannnelsAffidavit>[];
    actionColumns: ActionColumn[];
    headerFilters: CommonFilter[];
    headerActions: ActionHeader[];
    SnackBar: () => JSX.Element;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitPage.propTypes = propTypes;

export default ChannelsAffidavitPage;
