import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FadeIn from '../FadeIn';
import CustomLoader from '../CustomLoader';
import AlertNoData from '../AlertNoData';
import St from './CommonGrid.styled';
import HeaderFilters from './Extra/HeaderFilters';
import {
    ActionHeader,
    ExtraQueryFiltersProps,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import TableHeader from './Extra/TableHeader';
import TableHeaderActions from './Extra/TableHeaderActions';
import { tCommon } from 'constants/appConstants';

const CommonGrid = (props: Props) => {
    const {
        title,
        defaultSortFieldId,
        defaultSortAsc,
        data,
        finalColumns,
        loading,
        pageSize,
        totalRows,
        handleSort,
        handlePageChange,
        SnackBar,
        t,
        clientSidePagination = false,
        drawerWidth,
        showHeaderFilters,
        headerFilters,
        filterHeaderString,
        setFilterHeaderString,
        setShowHeaderFilters,
        refetch,
        setSearchText,
        resource,
        useGetData,
        canDisable,
        canExport = true,
        canSearch,
        exportName,
        conditionalRowStyles,
        finalColumnsForExport,
        extraActionsInHeader,
        extraFilters,
    } = props;

    const MemorizedTableHeaderActions = React.useMemo(
        () => (
            <TableHeaderActions
                columns={finalColumnsForExport}
                loading={loading}
                resource={resource}
                setSearchText={setSearchText}
                total={totalRows}
                fetchData={useGetData}
                canDisable={canDisable}
                canExport={canExport}
                canSearch={canSearch}
                exportName={exportName || title}
                filterHeaderString={filterHeaderString}
                extraActionsInHeader={extraActionsInHeader}
                extraFilters={extraFilters}
            />
        ),
        [t, loading, refetch, showHeaderFilters, setShowHeaderFilters],
    );

    return (
        <St.GridWrapper $drawerWidth={drawerWidth}>
            <FadeIn>
                <TableHeader
                    title={title}
                    loading={loading}
                    refetch={refetch}
                    showHeaderFilters={showHeaderFilters}
                    headerFilters={headerFilters}
                    setShowHeaderFilters={setShowHeaderFilters}
                />
            </FadeIn>
            <FadeIn>{MemorizedTableHeaderActions}</FadeIn>
            {showHeaderFilters && headerFilters && (
                <FadeIn>
                    <HeaderFilters
                        filters={headerFilters}
                        filterHeaderString={filterHeaderString}
                        setFilterHeaderString={setFilterHeaderString}
                    />
                </FadeIn>
            )}
            <FadeIn>
                <St.DataTableStyled
                    dense
                    className="react_dataTable"
                    data={data ?? []}
                    columns={finalColumns}
                    noDataComponent={
                        data === null ? (
                            <AlertNoData title={t('unexpected_response_error', tCommon)} />
                        ) : (
                            <AlertNoData title={t('no_data_was_found', tCommon)} />
                        )
                    }
                    title={title}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination
                    paginationServer={!clientSidePagination}
                    paginationPerPage={pageSize}
                    paginationTotalRows={totalRows}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: t('of', tCommon),
                    }}
                    onChangePage={!clientSidePagination ? handlePageChange : () => {}}
                    sortServer={!clientSidePagination}
                    noHeader
                    onSort={!clientSidePagination ? handleSort : () => {}}
                    defaultSortFieldId={defaultSortFieldId}
                    defaultSortAsc={defaultSortAsc}
                    conditionalRowStyles={conditionalRowStyles}
                    theme="palierGridTheme"
                />
            </FadeIn>
            <SnackBar />
        </St.GridWrapper>
    );
};

const propTypes = {
    title: PropTypes.string.isRequired,
    defaultSortFieldId: PropTypes.string,
    defaultSortAsc: PropTypes.bool,
    data: PropTypes.any,
    finalColumns: PropTypes.arrayOf(PropTypes.any).isRequired,
    finalColumnsForExport: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    SnackBar: PropTypes.any.isRequired,
    t: PropTypes.any.isRequired,
    clientSidePagination: PropTypes.bool.isRequired,
    showHeaderFilters: PropTypes.bool.isRequired,
    setFilterHeaderString: PropTypes.func.isRequired,
    setShowHeaderFilters: PropTypes.func.isRequired,
    setSearchText: PropTypes.func.isRequired,
    resource: PropTypes.string.isRequired,
    useGetData: PropTypes.any.isRequired,
    canSearch: PropTypes.bool,
    canDisable: PropTypes.bool,
    exportName: PropTypes.string,
};

interface extraProps {
    handleSort: (column: any, sortDirection: string) => void;
    handlePageChange: (pag: number) => void;
    drawerWidth: number;
    conditionalRowStyles?: { when: Function; style: Object }[];
    headerFilters?: CommonFilter[];
    refetch: (value: boolean) => void;
    extraFilters?: Array<ExtraQueryFiltersProps>;
    filterHeaderString: string;
    canExport?: boolean;
    defaultSortDirection?: 'asc' | 'desc';
    extraActionsInHeader?: ActionHeader[];
    totalRows?: number;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonGrid.propTypes = propTypes;

export default CommonGrid;
