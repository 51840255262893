import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { statesToShowActions } from 'utils/helpers/channelsCommonHelper';
import { Divider, Grid, Typography } from '@mui/material';
import ChannelsModalActionButton from 'components/common/ChannelsModalActionButton';
import ChannelsModalActions from 'components/common/ChannelsModalActions';
import RejectForm from 'components/common/RejectForm';
import { COMPLETED } from 'constants/appConstants';

const ChannelsFundDetailActions = ({
    id,
    status,
    isLoading,
    isLoadingReject,
    reason,
    action,
    setAction,
    setReason,
    handleAcquireOrReleaseOrder,
    handleSubmit,
    close,
    t,
}: Props) => (
    <>
        {statesToShowActions.includes(status) && (
            <>
                {status !== COMPLETED && (
                    <>
                        <Divider sx={{ width: '100%', mt: 1.25 }} />

                        <ChannelsModalActionButton status={status} setAction={setAction} />
                    </>
                )}

                <Grid
                    container
                    item
                    direction="column"
                    alignSelf="baseline"
                    xs={6}
                    spacing={2}
                    ml={1.25}>
                    {action === 'approve' && (
                        <Grid item xs>
                            <Typography>
                                {t('channels_fund_detail_option_selected_approve')}
                            </Typography>
                        </Grid>
                    )}

                    {action === 'reject' && <RejectForm setReason={setReason} reason={reason} />}
                </Grid>
            </>
        )}

        <Divider sx={{ width: '100%', mt: 2 }} />

        <ChannelsModalActions
            id={id}
            type="FUND"
            status={status}
            loading={isLoading || isLoadingReject}
            disabled={isLoading || isLoadingReject}
            close={close}
            handleSubmit={handleSubmit}
            handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
        />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    id: string;
    status: string;
    isLoading: boolean;
    isLoadingReject: boolean;
    reason?: string;
    action: string;
    setAction(action: string): void;
    close(): void;
    setReason(reason: string): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
    handleSubmit(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsFundDetailActions.propTypes = propTypes;
ChannelsFundDetailActions.defaultProps = defaultProps;

export default ChannelsFundDetailActions;
