import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ExportButton from './ExportButton';
import { useTranslation } from 'react-i18next';
import { CommonColumn, GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import useExportData from 'hooks/common/CommonGrid/exportData.hooks';

const ExportButtonContainer = (props: Props) => {
    const { fetchData, exportTitle, filterHeaderString, total, columns } = props;
    const { t } = useTranslation();
    const { loading: exportLoading, handleExportData } = useExportData(
        fetchData,
        columns,
        exportTitle,
        // filterHeaderString,
        `${filterHeaderString}&page=1&pageSize=${total !== 0 && !!total ? total : 10}&export=true`,
    );

    const childProps = {
        ...props,
        t,
        exportLoading,
        handleExportData,
    };

    return <ExportButton {...childProps} />;
};

const propTypes = {
    total: PropTypes.number.isRequired,
    exportTitle: PropTypes.string.isRequired,
    filterHeaderString: PropTypes.string.isRequired,
};

interface extraProps {
    fetchData(params: GridHookParams): any;
    columns: CommonColumn<any>[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ExportButtonContainer.propTypes = propTypes;

export default ExportButtonContainer;
