import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import St from './ClientExtractionsDetail.styled';
import ActionButton from 'components/common/ActionButton';
import {
    BROKER,
    DESTINATION,
    INTERMEDIARY,
    LOCAL_ACCOUNTS,
    PENDING,
    PENDING_WITHDRAWAL,
    tChannelsCommon,
    tCommon,
} from 'constants/appConstants';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';
import { UPDATE_WITHDRAWALS } from 'constants/abilities.constants';
import { ExtractionsApi } from 'types/clientExtractions/clientExtractions.api.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import ChannelsModalActionButton from 'components/common/ChannelsModalActionButton';
import RejectForm from 'components/common/RejectForm';
import { SortedBankAccount } from 'types/clientExtractions/clientExtractions.types';
import BankAccountDetail from 'components/common/BankAccountDetail';

const ClientExtractionsDetail = ({
    row: {
        entryDate,
        channel,
        user,
        bankAccount: { type, accountNumber },
        customerCode,
        amount,
        currency,
        reason,
        comment,
        conditions,
        status,
    },
    actionSelector,
    rejectReason,
    accountsInfo,
    setRejectReason,
    handleSubmit,
    setActionSelector,
    isHiddenByAbility,
    close,
    t,
}: Props) => (
    <Grid container spacing={2} px={3} mt={1.5} alignItems="baseline">
        <Grid container item spacing={1} xs={6}>
            <St.DetailTitle>{t('information', tCommon)}</St.DetailTitle>
            <ChannelsModalItemGrid
                label={t('extractions_list_entry_date_column')}
                column={6}
                text={formatDateWithHour(entryDate)}
            />
            <ChannelsModalItemGrid
                label={t('extractions_list_channel_column')}
                column={6}
                text={getFullName(channel)}
            />
            <ChannelsModalItemGrid
                label={t('extractions_list_customer_name_column')}
                column={6}
                text={getFullName(user)}
            />
            <ChannelsModalItemGrid
                label={t('extractions_list_customer_number_column')}
                column={6}
                text={customerCode}
            />
            <ChannelsModalItemGrid
                label={t('extractions_list_currency_column')}
                column={6}
                text={currency}
            />
            <ChannelsModalItemGrid
                label={t('extractions_list_amount_column')}
                column={6}
                text={convertCurrencyFormat(amount, currency)}
            />
            {reason && (
                <>
                    <Divider sx={{ width: '100%', mt: 1.25 }} />
                    <ChannelsModalItemGrid
                        label={t('extractions_list_reason_column')}
                        text={reason}
                    />
                </>
            )}
        </Grid>

        {conditions && (
            <>
                <Grid container item xs={6}>
                    <St.DetailTitle>{t('additional_information', tCommon)}</St.DetailTitle>
                    {comment && (
                        <ChannelsModalItemGrid label={t('extractions_comment')} text={comment} />
                    )}
                    <FormGroup sx={{ mt: 1 }}>
                        {Object.entries(conditions)
                            .filter(([key]) => key !== 'usdMep' && key !== 'usdWire')
                            .map(([key, condition]) => (
                                <FormControlLabel
                                    disabled
                                    key={key}
                                    label={
                                        <Typography fontSize={14}>
                                            {t(`client_extractions_detail_${key}`)}
                                        </Typography>
                                    }
                                    control={<Checkbox sx={{ p: 0.3 }} size="small" />}
                                    checked={condition}
                                    sx={{ ml: 0 }}
                                />
                            ))}
                    </FormGroup>
                </Grid>
                <Divider sx={{ width: '100%', my: 1 }} />
            </>
        )}

        <Grid container item xs={6} spacing={1}>
            <St.DetailTitle>{t('client_extractions_destination_account')}</St.DetailTitle>
            {LOCAL_ACCOUNTS.includes(type) && (
                <>
                    <ChannelsModalItemGrid
                        label={t('extractions_list_customer_id_column')}
                        text={user.docNumber}
                    />
                    <ChannelsModalItemGrid
                        label={t('extractions_list_cbu_column')}
                        text={accountNumber}
                    />
                </>
            )}

            {accountsInfo?.BROKER && (
                <BankAccountDetail type={BROKER} accountDetail={accountsInfo.BROKER} />
            )}

            {accountsInfo?.DESTINATION && (
                <BankAccountDetail type={DESTINATION} accountDetail={accountsInfo.DESTINATION} />
            )}
        </Grid>

        {accountsInfo?.INTERMEDIARY && (
            <Grid item xs={6}>
                <St.DetailTitle>{t('client_extractions_intermediary_accounts')}</St.DetailTitle>
                {accountsInfo.INTERMEDIARY.map(accountDetail => (
                    <BankAccountDetail
                        key={accountDetail.id}
                        type={INTERMEDIARY}
                        accountDetail={accountDetail}
                    />
                ))}
            </Grid>
        )}
        {status === PENDING && !isHiddenByAbility(UPDATE_WITHDRAWALS) && (
            <>
                <Grid container item xs={12}>
                    <Divider sx={{ width: '100%', my: 1 }} />
                    <Grid item xs={6} pt={0}>
                        <ChannelsModalActionButton
                            status={PENDING_WITHDRAWAL}
                            setAction={type => setActionSelector(type)}
                        />
                    </Grid>
                    {actionSelector.includes('approve') && (
                        <>
                            <Typography fontWeight={600}>
                                {t('client_extractions_selection')}: &nbsp;
                            </Typography>
                            <Typography>{t(actionSelector, tChannelsCommon)}</Typography>
                        </>
                    )}
                    {actionSelector === 'reject' && (
                        <RejectForm setReason={setRejectReason} reason={rejectReason} />
                    )}
                </Grid>

                <Divider sx={{ width: '100%', mt: 1.25 }} />
                <Grid container item xs={12} display="flex" justifyContent="end" pt={1} spacing={1}>
                    <Grid item xs={2.5}>
                        <ActionButton
                            variant="outlined"
                            onClick={close}
                            sx={{ mt: 0, width: '100%' }}>
                            {t('back', tCommon)}
                        </ActionButton>
                    </Grid>
                    <Grid item xs={2.5}>
                        <ActionButton
                            sx={{ mt: 0, width: '100%' }}
                            disabled={!actionSelector}
                            onClick={handleSubmit}>
                            {t('save', tCommon)}
                        </ActionButton>
                    </Grid>
                </Grid>
            </>
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    row: ExtractionsApi;
    rejectReason: string;
    accountsInfo?: SortedBankAccount;
    setRejectReason(rejectReason: string): void;
    handleSubmit(): void;
    actionSelector: string;
    setActionSelector(actionSelector: string | null): void;
    isHiddenByAbility: (ability: string) => boolean;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientExtractionsDetail.propTypes = propTypes;
ClientExtractionsDetail.defaultProps = defaultProps;

export default ClientExtractionsDetail;
